<template>
    <div>
      <v-container>
        <v-row>
          <v-col>
            <v-simple-table>
              <thead>
                <tr>
                  <th style="font-size: 18px; font-weight: bold;">Users</th>
                  <th style="font-size: 18px; font-weight: bold;">Email</th>
                  <th style="font-size: 18px; font-weight: bold;">Message</th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  v-for="(message, index) in messageList" 
                  :key="index" 
                  @click="openMessageDialog(message)"
                  style="cursor: pointer;"
                >
                  <td>{{ message.name }}</td>
                  <td>{{ message.email }}</td>
                  <td>{{ message.message }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>
  
      <!-- Message Detailed View Dialog -->
      <v-dialog v-model="messageDetailedViewDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Message Details</span>
          </v-card-title>
          <v-card-text>
            <p><strong>User:</strong> {{ selectedMessage.name }}</p>
            <p><strong>Email:</strong> {{ selectedMessage.email }}</p>
            <p><strong>Message:</strong></p>
            <p>{{ selectedMessage.message }}</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="messageDetailedViewDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        messageList: [],
        messageDetailedViewDialog: false,
        selectedMessage: {},
      };
    },
    mounted() {
      this.getMessageList();
    },
    methods: {
      getMessageList() {
        this.appLoading = true;
        axios({
          url: "/message/list",
          method: "GET",
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.messageList = response.data.data;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      openMessageDialog(message) {
        this.selectedMessage = message;
        this.messageDetailedViewDialog = true;
      },
      navigateToSubPlans() {
        this.$router.push({ path: '/subcategory' });
      },
      navigateToRegions() {
        this.$router.push({ path: '/banner' });
      },
      navigateToCountries() {
        this.$router.push({ path: '/Country' });
      },
      navigateToEmail() {
        this.$router.push({ path: '/userList', query: { method: 'Email' } });
      },
      navigateToApple() {
        this.$router.push({ path: '/userList', query: { method: 'Apple' } });
      },
      navigateToGmail() {
        this.$router.push({ path: '/userList', query: { method: 'Google' } });
      },
    },
  };
  </script>
  